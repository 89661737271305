<template>
  <div ref="tollfree-info" class="tollfree-info">
    <div v-if="isLoading" class="text-center mt-4">
      <b-spinner></b-spinner>
    </div>
    <div v-else>
      <div v-if="hasNumber" class="card">
        <div class="card-body">
          <h5>Sending Information</h5>
          <p>Your customers will receive SMS messages from the number listed below.</p>
          <b-table :items="numbers" :fields="datatable.columns" :sort-by.sync="datatable.queries.sort_by"
            :sort-desc.sync="datatable.queries.sort_desc" :per-page="datatable.queries.per_page"
            :current-page="datatable.queries.page">
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
            <template v-slot:cell(number)="data">
              {{ data.item.number | nationalNumber }}
            </template>
            <template v-slot:cell(region)>
              <div class="d-flex align-items-center"><span class="flag-icon us mr-1"></span>United State</div>
              <div class="d-flex align-items-center"><span class="flag-icon canada mr-1"></span>Canada</div>
            </template>
            <template v-slot:cell(status)="data">
              <span v-if="isVerified" class="badge bg-success text-white"><i class="uil uil-check-circle"></i>
                {{ statusLabel | capitalize }}</span>
              <span v-else-if="isRejected" class="badge bg-danger text-white"><i class="uil uil-times"></i>
                {{ statusLabel | capitalize }}</span>
              <span v-else-if="!data.item.status" class="badge bg-dark text-white"><i class="uil uil-upload"></i>
                Draft</span>
              <span v-else class="badge text-white bg-warning">
                <i class="uil uil-refresh"></i> {{
                  statusLabel | capitalize }}</span>
            </template>
            <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | moment('YYYY-MM-DD HH:mm:ss') }}
            </template>
            <template v-slot:cell(actions)="data">
              <a v-if="data && !isVerified" href="javascript:;" class="btn btn-primary btn-sm ml-1" @click="onEdit">
                <i class="uil uil-edit"></i> Edit
              </a>
            </template>
          </b-table>
          <div v-if="isRejected" class="mt-4 alert alert-danger">
            <h5 class="text-white">Sorry!</h5>
            <div class="d-block">SMS registration failed due to business information provided was not able to be verified.
              If you have any questions contact your admin at <a :href="`mailto:${agencyEmail}`"
                class="text-white font-weight-bold"><u>{{ agencyEmail }}</u></a>
            </div>
          </div>
        </div>
      </div>
      <div ref="tollfree-form" class="card">
        <div class="card-body">
          <div v-if="!hideForm" class="mt-4 alert alert-primary">
            <h5 class="text-white">What's your company information?</h5>
            <p>Your information will only be used to verify a toll-free phone number so that you can send SMS messages
              in
              the United States and Canada.</p>
          </div>
          <ValidationObserver v-slot="{ handleSubmit }" ref="businessForm">
            <form @submit.prevent="handleSubmit(onSubmitForm)">
              <div v-if="!hideForm" class="row mt-5 mb-4">
                <div class="col-md-4">
                  <TextInput v-model="business.full_name" name="FullName" label="Full Name" sublabel="*"
                    help="First & last name of the Message sender point of contact. This contact should be at the end-business level, not the ISV/reseller."
                    rules="required" :disabled="formDisabled" />
                </div>
                <div class="col-md-4">
                  <PhoneInput v-model="business.phone" name="PhoneNumber" label="Company Phone Number" sublabel="*"
                    help="Phone number of the Message sender point of contact." rules="required|phone"
                    :disabled="formDisabled" />
                </div>
                <div class="col-md-4">
                  <TextInput v-model="business.legal_name" name="CompanyName" label="Legal Company Name" sublabel="*"
                    rules="required" :disabled="formDisabled" />
                </div>
                <div class="col-md-4">
                  <TextInput v-model="business.website" name="WebsiteURL" label="Website URL" sublabel="*"
                    rules="required" :disabled="formDisabled" />
                </div>
                <div class="col-md-12 mt-3">
                  <div class="form-group">
                    <label class="form-control-label">
                      Message Sender Primary Company Address or Corporate Headquarters
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <TextInput v-model="business.address1" name="Address1" rules="required" label="Address Line 1"
                    sublabel="*" :disabled="formDisabled" />
                </div>
                <div class="col-md-4">
                  <TextInput v-model="business.address2" name="Address2" label="Address Line 2"
                    :disabled="formDisabled" />
                </div>
                <div class="col-md-4">
                  <TextInput v-model="business.city" name="City" rules="required" label="City" sublabel="*"
                    :disabled="formDisabled" />
                </div>
                <div class="col-md-4">
                  <TextInput v-model="business.state" name="State" rules="required" label="State" sublabel="*"
                    :disabled="formDisabled" />
                </div>
                <div class="col-md-4">
                  <TextInput v-model="business.zip" name="ZIP/Postal" rules="required" label="ZIP/Postal" sublabel="*"
                    :disabled="formDisabled" />
                </div>
                <div class="col-md-4">
                  <ValidationProvider v-slot="{ failed, errors }" rules="required" name="message_volume">
                    <label>Estimated Monthly Message Volume <span class="text-danger">*</span></label>
                    <multiselect v-model="business.message_volume" :allow-empty="false" :options="messageVolumes"
                      :multiple="false" :class="{ 'is-invalid': failed }" track-by="value" label="label"
                      :show-labels="false" :disabled="formDisabled">
                    </multiselect>
                    <b-form-invalid-feedback v-if="errors.length">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <h5 class="h5">SMS Message Add-ons</h5>
                  <label>Organizational Prefix <span class="text-danger">*</span> <span v-b-tooltip.hover
                      class="cursor-pointer"
                      title="Unlike email, SMS doesn't have a built in way to identify who sends messages. Prefixed will be added to the beginning of your SMS message to clarify your identity."><i
                        class="uil uil-question-circle"></i></span></label>
                  <TextInput v-model="organizationalPrefix" name="OrganizationalPrefix" rules="required"
                    help="A recognizable prefix will ensure your audience knows who you are." />
                  <b-button variant="primary" :disabled="processing" type="button" class="btn-sm" @click="updatePrefix">
                    <b-spinner v-if="processing" small />
                    <span v-else>Update Prefix</span>
                  </b-button>
                </div>
              </div>
              <b-form-group v-if="!hideForm && !formDisabled" class="text-center">
                <b-button variant="primary" :disabled="processing || formDisabled" type="submit">
                  <b-spinner v-if="processing" small />
                  <span v-else>Submit Registration</span>
                </b-button>
              </b-form-group>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <b-modal v-model="showConfirmChargeModal" no-close-on-backdrop hide-header-close>
        <template v-slot:modal-title>
          <h5 class="text-primary">Confirmation</h5>
        </template>
        <p v-if="isNew">By Pressing Submit, You agree to process your registration for your text messages to continue to go
          through without interruption.
        </p>
        <p v-else>By Pressing Submit, you agree to resubmit your registration.
        </p>
        <template v-slot:modal-footer>
          <b-button variant="light" @click="showConfirmChargeModal = false">Cancel</b-button>
          <b-button variant="primary" @click="submitForm">
            <b-spinner v-if="processing" small />
            <span v-else>Submit</span>
          </b-button>
        </template>
      </b-modal>

      <b-modal v-model="showConfirmUpdateModal" no-close-on-backdrop hide-header-close>
        <template v-slot:modal-title>
          <h5 class="text-primary">Confirmation</h5>
        </template>
        <p>Are you sure you want to update registration information under processing?</p>
        <template v-slot:modal-footer>
          <b-button variant="light" @click="showConfirmUpdateModal = false">Cancel</b-button>
          <b-button variant="primary" @click="submitForm">
            <b-spinner v-if="processing" small />
            <span v-else>Update</span>
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
      
<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import VueScrollTo from 'vue-scrollto'

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      isLoading: false,
      processing: false,
      verification: null,
      isEdit: false,
      organizationalPrefix: '',
      business: {
        full_name: '',
        phone: '',
        legal_name: '',
        website: '',
        message_volume: undefined,
      },
      messageVolumes: [
        { value: "10", label: "10" },
        { value: "100", label: "100" },
        { value: "1000", label: "1,000" },
        { value: "10000", label: "10,000" },
        { value: "100000", label: "100,000" },
        { value: "250000", label: "250,000" },
        { value: "500000", label: "500,000" },
        { value: "750000", label: "750,000" },
        { value: "1000000", label: "1,000,000" },
        { value: "5000000", label: "5,000,000" },
        { value: "10000000", label: "10,000,000" },
      ],
      yesNo: [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
      ],
      datatable: {
        columns: [
          { key: 'number', label: 'Number', sortable: false },
          { key: 'type', label: 'Type', sortable: false },
          { key: 'region', label: 'Supported Region', sortable: false },
          { key: 'status', label: 'Status', sortable: false },
          { key: 'created_at', label: 'CreatedAt', sortable: false },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: false,
        },
      },
      isNew: true,
      showConfirmChargeModal: false,
      showConfirmUpdateModal: false,
    }
  },

  computed: {
    formDisabled() {
      return this.hasNumber && this.status && !this.isEdit
    },

    status() {
      return this.verification && this.verification.main_status
    },

    hasNumber() {
      return this.numbers && !!this.numbers.length
    },

    numbers() {
      let res = []
      if (this.authBusiness && this.authBusiness.phone_number) {
        let number = this.authBusiness.phone_number
        res.push({
          number,
          type: 'Tollfree',
          status: this.status,
          api_submitted: this.verification ? this.verification.api_submitted : undefined,
          created_at: this.verification ? this.verification.created_at : undefined,
        })
      }
      return res
    },

    user() {
      return this.$store.getters['auth/user']
    },

    authBusiness() {
      return this.$store.getters['auth/business']
    },

    agency() {
      return this.user && this.user.business && this.user.business.agency
    },

    agencyEmail() {
      return this.agency && this.agency.email;
    },

    isVerified() {
      if (!this.status)
        return false
      return ['verified', 'resolved - verified'].includes(this.status.toLowerCase())
    },

    isRejected() {
      if (!this.status)
        return false
      return ['unverified', 'invalid_status', 'resolved - not verified'].includes(this.status.toLowerCase())
    },

    declinedReason() {
      return this.verification && this.verification.reason_declined
    },

    statusLabel() {
      let res = 'Draft';
      if (this.isVerified) {
        res = 'Verified & Approved';
      } else if (this.isRejected) {
        res = 'Not Approved';
      } else if (this.status) {
        res = this.status;
      }
      return res
    },

    hideForm() {
      return this.isVerified && !this.business.full_name
    }
  },

  watch: {

  },

  mounted() {
    this.business.canadian_traffic = this.yesNo[0]
    this.initData()
  },

  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      if (element)
        VueScrollTo.scrollTo(element, 200)
    },

    updatePrefix() {
      if (!this.organizationalPrefix) {
        this.$refs.businessForm.setErrors({
          'OrganizationalPrefix': ['OrganizationalPrefix is not valid.']
        })
        return
      }

      this.processing = true;
      let params = {
        organizational_prefix: this.organizationalPrefix,
      }
      this.$store
        .dispatch('business/updatePrefix', params)
        .then((data) => {
          this.processing = false
        })
    },

    onEdit() {
      this.isEdit = true;
      this.goto('tollfree-form')
    },
    initData() {
      this.getNumberVerification()
      this.showConfirmChargeModal = false
      this.showConfirmUpdateModal = false
      this.isNew = true
    },

    getNumberVerification() {
      this.isLoading = true;
      this.$store
        .dispatch('business/getNumberVerification')
        .then((data) => {
          this.isLoading = false;
          if (data.data) {
            this.verification = data.data
            this.organizationalPrefix = data.data.organizational_prefix
            if (this.verification.data) {
              this.business = this.verification.data
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        })
    },

    onSubmitForm() {
      if (this.verification && this.verification.id) {
        if (this.verification.status === 'UNVERIFIED') {
          this.isNew = false
          this.showConfirmChargeModal = true
        } else {
          this.showConfirmUpdateModal = true
        }
      } else {
        this.showConfirmChargeModal = true
      }
    },

    submitForm() {
      this.processing = true
      const params = {
        ...this.business,
        organizational_prefix: this.organizationalPrefix,
      }

      this.$store
        .dispatch('business/numberVerify', params)
        .then(() => {
          this.isEdit = false;
          this.$store.dispatch('auth/getProfile').then(() => {
            this.processing = false
            Vue.$toast.open({
              message: 'Verification Request has been submitted successfully',
              type: 'success',
              duration: 5000,
            })
            this.initData()
            this.goto('tollfree-info')
          })
        })
        .catch((err) => {
          if (err.response.data.errors) {
          }
          this.processing = false
        })
    },
  },
}
</script>
      
<style lang="scss">
.tollfree-info {
  .wizard-btn {
    color: #fff;
    background-color: #4284f4;
    border-color: #4284f4;
  }

  .multiselect--disabled {
    background-color: #d0d0d0 !important;

    .multiselect__tags,
    .multiselect__single,
    .multiselect__select {
      background-color: #d0d0d0 !important;
    }
  }
}
</style>
